<template>
    <div class="phi-type-editor">

        <phi-drawer :open="!editorOpen && !iconDrawer">
            <div class="face phi-media" @click="editorOpen = true">
                <div class="phi-media-figure">
                    <img v-if="type.icon" :src="type.icon" alt="type.singular" />
                    <mu-icon v-else value="add" :size="40"></mu-icon>
                </div>
                <h3 class="phi-media-body">
                    <template v-if="type.singular">{{ $t(type.singular) }}</template>
                    <template v-else>{{ label }}</template>
                </h3>
            </div>
        </phi-drawer>

        <phi-drawer :open="editorOpen && !iconDrawer">
            <div class="editor">
                <div class="phi-media">
                    <div class="phi-media-figure" @click="iconDrawer = !iconDrawer">
                        <img v-if="type.icon" :src="type.icon" alt="type.singular" />
                        <mu-icon v-else value="help" class="empty" :size="40"></mu-icon>
                    </div>
                    <div class="phi-media-body">
                        <div class="type-name">
                            <select v-model="type.gender">
                                <option :value="1">{{ $t('noun.nounPersonMale') }}</option>
                                <option :value="0">{{ $t('noun.nounPersonFemale') }}</option>
                            </select>
                            <phi-input :label="$t('noun.nameSingular')" v-model="type.singular" @keydown="sanitizer($event)"></phi-input>
                            <phi-input :label="$t('noun.namePlural')" v-model="type.plural" @keydown="sanitizer($event)"></phi-input>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <button type="button" class="ok" @click="save" :disabled="isEmpty">{{ $t('OK') }}</button>
                    <button type="button" class="cancel" @click="cancel">{{ $t('action.cancel') }}</button>
                </div>
            </div>
        </phi-drawer>

        <phi-drawer :open="iconDrawer">
            <div class="icon-picker-drawer">
                <phi-icon-picker class="icon-picker" v-model="type.icon" @input="iconDrawer = false" @color="type.color = arguments[0]"></phi-icon-picker>
            </div>
        </phi-drawer>

    </div>
</template>

<script>
import PhiIconPicker from '../Icon/Picker.vue';

export default {
    name: 'phi-type-editor',
    components: {PhiIconPicker},
    props: {
        value: {
            type: Object,
            required: true
        },

        label: {
            type: String
        }
    },

    data() {
        return {
            type: Object.assign({}, this.value),
            initialValue: Object.assign({}, this.value),

            iconDrawer: false,
            editorOpen: false
        };
    },

    computed: {
        isEmpty() {
            return this.type.singular == null || !this.type.singular.trim() || this.type.plural == null || !this.type.plural.trim() || !this.type.icon;
        }
    },

    methods: {
        save() {
            this.editorOpen = false;
            this.iconDrawer = false;
            this.$emit("input", this.type);
        },

        cancel() {
            this.editorOpen = false;
            this.iconDrawer = false;
            this.type = Object.assign({}, this.initialValue);
            this.$emit("cancel");
        },

		sanitizer(event) {
            if(!/^[A-Za-z0-9 \s]+$/g.test(event.key)){
				event.preventDefault();
			}
        }
    },

    watch: {
        value(newValue, oldValue) {
            this.type         = Object.assign({}, this.value);
            this.initialValue = Object.assign({}, this.value);
        }
    }
}
</script>

<style lang="scss">
.phi-type-editor {

    .face {
        align-items: center;
        font-size: 20px;
        cursor: pointer;
    }

    & > .phi-media-figure {
        min-width: 40px;
    }

    .type-name {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .phi-input {
            flex: 1;
            margin: 0 1em;
            min-width: 150px;
        }
    }

    .editor {
        .empty {
            cursor: pointer;
            color: red;
            opacity: .6;

            &:hover {
                opacity: .8;
            }
        }
    }

    .actions {
        display: flex;

        button {
            flex: 1;
            cursor: pointer;
            margin: none;
            padding: 12px 18px;
            border: none;
            background-color: transparent;
            font-size: 1em;
            text-align: left;

            &:hover {
                background-color: rgba(0,0,0,.04);
            }
        }

        .cancel {
            margin-left: 20px;
            background: transparent;
            border: none;
            color: #444;
        }
    }

}

</style>